import styled from 'styled-components';

export const ProfileCardContainer = styled.div<{ $isKycSyncAllowed: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  ${({ $isKycSyncAllowed }) => !$isKycSyncAllowed && 'opacity: 0.5; cursor: default;'}
`;

export const ProfileCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const ProfileName = styled.div`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray88};
  text-transform: capitalize;
`;

export const ProfileType = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
  text-transform: capitalize;
`;
