import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Avatar } from '../../../../components';
import type { ProfileInfo } from '../../../../types';
import { GetKycSyncProfilesResult } from '../../../../api/controllers/kyc-sync';

type ProfileCardProps = {
  profile: {
    profileName: string;
    profileUid: string;
    kycSyncAllowed: boolean;
  };
  onClick: (profileUid: string) => void;
};

export const ProfileCard: FC<ProfileCardProps> = (props) => {
  const { profile, onClick } = props;
  const { t } = useTranslation();

  // TODO: clarify avatar src, after backend implementing
  return (
    <S.ProfileCardContainer
      onClick={() => profile.kycSyncAllowed && onClick(profile.profileUid)}
      $isKycSyncAllowed={profile.kycSyncAllowed}
    >
      <Avatar size="large" />

      <S.ProfileCardContent>
        <S.ProfileName>{profile.profileName}</S.ProfileName>
      </S.ProfileCardContent>
    </S.ProfileCardContainer>
  );
};
